'use strict';

const cssConstants=require("../utils/cssConstants");
const $cache = {
    passwordField: $('.js-password'),
    passwordHelper: $('.js-password-helper'),
    passwordHelperList: $('.js-password-helper-list'),
    pwdLength: $('.js-pwd-length'),
    pwdUpper: $('.js-pwd-upper'),
    pwdLetter: $('.js-pwd-letter'),
    pwdNumber: $('.js-pwd-number'),
    pwdSpecial: $('.js-pwd-special')
};

module.exports = {
    passwordValidation: function () {
        $cache.passwordField.on('blur keyup', function (e) {
            var pwdVal = $cache.passwordField.val();
            var $formGroup = $cache.passwordField.parents('.form-group');
            var $fieldError = $formGroup.find('.invalid-feedback');
            var upperLetterRegExp = /(?=.*[A-Z]{1}.*)/;
            var lowerLetterRegExp = /(?=.*[a-z]{1}.*)/;
            var oneNumberRegExp = /^.*\d.*$/;
            var specialSymbolsRegExp = /.*[\s:|`|\\?|\\~|\\!|\\@|\\#|\\$|\\%|\\^|\\&|\\*|\\(|\\)|\\+|\\=|\\[|\\{|\]|\\}|\\||\\|\\'|\\<|\\,|\\.|\\>|\\?|\\/|\\""|\\;|\\:|\\_|\\¿|\\§|\\«|\\»|\\ω|\\⊙|\\¤|\\°|\\℃|\\℉|\\€|\\¥|\\£|\\¢|\\¡|\\®|\\©|\\-].*/;

            if (e.type === 'keyup' && !$cache.passwordField.hasClass(cssConstants.INVALID) && !$cache.passwordField.hasClass(cssConstants.VALID)) {
                return;
            }

            if (pwdVal.length < 9) {
                $cache.pwdLength.addClass(cssConstants.SHOW_BLOCK);
            } else {
                $cache.pwdLength.removeClass(cssConstants.SHOW_BLOCK);
            }

            if (!pwdVal.match(upperLetterRegExp)) {
                $cache.pwdUpper.addClass(cssConstants.SHOW_BLOCK);
            } else {
                $cache.pwdUpper.removeClass(cssConstants.SHOW_BLOCK);
            }

            if (!pwdVal.match(lowerLetterRegExp)) {
                $cache.pwdLetter.addClass(cssConstants.SHOW_BLOCK);
            } else {
                $cache.pwdLetter.removeClass(cssConstants.SHOW_BLOCK);
            }

            if (!pwdVal.match(oneNumberRegExp)) {
                $cache.pwdNumber.addClass(cssConstants.SHOW_BLOCK);
            } else {
                $cache.pwdNumber.removeClass(cssConstants.SHOW_BLOCK);
            }

            if (!pwdVal.match(specialSymbolsRegExp)) {
                $cache.pwdSpecial.addClass(cssConstants.SHOW_BLOCK);
            } else {
                $cache.pwdSpecial.removeClass(cssConstants.SHOW_BLOCK);
            }

            if ($cache.passwordHelperList.children('.' + cssConstants.SHOW_BLOCK).length) {
                $cache.passwordHelper.removeClass(cssConstants.HIDDEN);
                $cache.passwordField.addClass(cssConstants.INVALID).removeClass(cssConstants.VALID);
                $formGroup.addClass(cssConstants.INVALID);
                $fieldError.addClass(cssConstants.HIDDEN);
            } else {
                $cache.passwordHelper.addClass(cssConstants.HIDDEN);
                $cache.passwordField.removeClass(cssConstants.INVALID).addClass(cssConstants.VALID);
                $formGroup.removeClass(cssConstants.INVALID);
            }
        });
    }
};


