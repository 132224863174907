'use strict';

var formValidation = require('../components/formValidation');
var notifications = require('../components/notifications');
var location = window.location;

const selectors = {
    loginForm: '.js-login',
    registerForm: '.js-registration',
    reCaptchaTokenFieldLogin: '.js-gCaptchaToken-login',
    reCaptchaTokenFieldRegister: '.js-gCaptchaToken-register',
    resetPasswordForm: '.reset-password-form',
    requestPasswordTitle: '.request-password-title',
    requestPasswordBody: '.request-password-body',
    submitEmailBtn: '#submitEmailButton',
    sendEmailBtn: '.send-email-btn',
    loginSubmitBtn: '.js-login-submit',
    registerSubmitBtn: '.js-register-submit'
};

const $cache = {
    alertContainerLogin: $('.js-alert-wrapper-login'),
    alertContainerRegister: $('.js-alert-wrapper-register')
};

/**
 * Submits login form data
 * @param {obj} e Login form submit event object
 * @param {obj} form Login form
 */
function submitLoginForm(e, form) {
    var url = form.attr('action');
    $.spinner().start();
    $(selectors.loginSubmitBtn).attr('disabled', 'disabled');
    $(selectors.loginForm).trigger('login:submit', e);
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            $.spinner().stop();
            if (!data.success) {
                // Migrated customer logging in
                if (data.migrationRequired && data.redirectUrl) {
                    location.href = data.redirectUrl;
                    return;
                }
                formValidation(form, data);
                $(selectors.loginForm).trigger('login:error', data);
                setTimeout(function(){
                    $(selectors.loginSubmitBtn).attr('disabled', false);
                }, 1000);
                notifications.create(data.error, 'error', $cache.alertContainerLogin);
            } else {
                $(selectors.loginForm).trigger('login:success', data);
                location.href = data.redirectUrl;
            }
        },
        error: function (data) {
            if (data && data.responseJSON) {
                if (data.responseJSON.redirectUrl) {
                    location.href = data.responseJSON.redirectUrl;
                } else if (data.responseJSON.error) {
                    $(selectors.loginForm).trigger('login:error', data);
                    notifications.create(data.responseJSON.error, 'error', $cache.alertContainerLogin);
                }
            }
            $.spinner().stop();
            setTimeout(function(){
                $(selectors.loginSubmitBtn).attr('disabled', false);
            }, 1000);
        }
    });
}

/**
 * Submits registration form
 * @param {obj} e Register form submit event object
 * @param {obj} form Register form data
 */
function submitRegisterForm(e, form) {
    var url = form.attr('action');
    $(selectors.registerSubmitBtn).attr('disabled', 'disabled');
    $.spinner().start();
    $(selectors.registerForm).trigger('login:register', e);
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            $.spinner().stop();
            if (!data.success) {
                $(selectors.registerForm).trigger('login:register:error', data);
                formValidation(form, data);
                notifications.create(data.error, 'error', $cache.alertContainerRegister);
                setTimeout(function(){
                    $(selectors.registerSubmitBtn).attr('disabled', false);
                }, 1000);
            } else {
                $(selectors.registerForm).trigger('login:register:success', data);
                location.href = data.redirectUrl;
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                notifications.create(err.responseJSON.errorMessage, 'error', $cache.alertContainerRegister);
                setTimeout(function(){
                    $(selectors.registerSubmitBtn).attr('disabled', false);
                }, 1000);
            }
            $.spinner().stop();
        }
    });
}

module.exports = {
    login: function () {
        $(selectors.loginForm).on('submit', function (e) {
            var form = $(this);
            e.preventDefault();
            // If Google reCaptcha is enabled/loaded
            if (window.grecaptcha) {
                // Google reCaptcha Site key
                var gsiteKey = form.data('gsite-key');
                window.grecaptcha.ready(function () {
                    window.grecaptcha.execute(gsiteKey, {action: form.data('action')})
                        .then(function (token) {
                            form.find(selectors.reCaptchaTokenFieldLogin).val(token);
                            submitLoginForm(e, form);
                        });
                });
            } else {
                submitLoginForm(e, form);
            }
        });
    },
    register: function () {
        $(selectors.registerForm).submit(function (e) {
            var form = $(this);
            e.preventDefault();
            // If Google reCaptcha is enabled/loaded
            if (window.grecaptcha) {
                // Google reCaptcha Site key
                var gsiteKey = form.data('gsite-key');
                window.grecaptcha.ready(function () {
                    window.grecaptcha.execute(gsiteKey, {action: form.data('action')})
                        .then(function (token) {
                            form.find(selectors.reCaptchaTokenFieldRegister).val(token);
                            submitRegisterForm(e, form);
                        });
                });
            } else {
                submitRegisterForm(e, form);
            }
        });
    },
    resetPassword: function () {
        $(selectors.resetPasswordForm).submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $(selectors.requestPasswordTitle).text(data.receivedMsgHeading);
                        $(selectors.requestPasswordBody).empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $(selectors.submitEmailBtn).text(data.buttonText)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $(selectors.sendEmailBtn).empty()
                                .html('<a href="'
                                    + data.returnUrl
                                    + '" class="btn btn-secondary">'
                                    + data.buttonText + '</a>');
                        }
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
            return false;
        });
    },
};
